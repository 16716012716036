<template>
    <div>
        <form @submit.prevent="updateRecord">

            <!-- paste from hq below here -->
            <div class="">
                <h1>Notes</h1>
                <div>
                    <Checkbox
                        class="flex center bottom-15"
                        id="active"
                        value=1
                        v-model="record_data.active"
                        label="Active"
                        :disabled="!isEditable"
                    /></div>
                <div class="flex colx4 wrap">
                    <div><Input
                        type="text"
                        name="note_type"
                        id="note_type"
                        placeholder="Note Type"
                        v-model="record_data.note_type"
                        label="Note Type"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="note"
                        id="note"
                        placeholder="Note"
                        v-model="record_data.note"
                        label="Note"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="tags"
                        id="tags"
                        placeholder="Tags"
                        v-model="record_data.tags"
                        label="Tags"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="user_id"
                        id="user_id"
                        placeholder="User Id"
                        v-model="record_data.user_id"
                        label="User Id"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="client_id"
                        id="client_id"
                        placeholder="Client Id"
                        v-model="record_data.client_id"
                        label="Client Id"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="appt_id"
                        id="appt_id"
                        placeholder="Appt Id"
                        v-model="record_data.appt_id"
                        label="Appt Id"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="signature_id"
                        id="signature_id"
                        placeholder="Signature Id"
                        v-model="record_data.signature_id"
                        label="Signature Id"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="date"
                        name="signed_dayt"
                        id="signed_dayt"
                        placeholder="Signed Dayt"
                        v-model="record_data.signed_dayt"
                        label="Signed Dayt"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="tx_review_id"
                        id="tx_review_id"
                        placeholder="Tx Review Id"
                        v-model="record_data.tx_review_id"
                        label="Tx Review Id"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="prescription_id"
                        id="prescription_id"
                        placeholder="Prescription Id"
                        v-model="record_data.prescription_id"
                        label="Prescription Id"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="signature_id2"
                        id="signature_id2"
                        placeholder="Signature Id2"
                        v-model="record_data.signature_id2"
                        label="Signature Id2"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="date"
                        name="signed_dayt2"
                        id="signed_dayt2"
                        placeholder="Signed Dayt2"
                        v-model="record_data.signed_dayt2"
                        label="Signed Dayt2"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="provider_id"
                        id="provider_id"
                        placeholder="Provider Id"
                        v-model="record_data.provider_id"
                        label="Provider Id"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="attributes_values"
                        id="attributes_values"
                        placeholder="Attributes Values"
                        v-model="record_data.attributes_values"
                        label="Attributes Values"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="diags"
                        id="diags"
                        placeholder="Diags"
                        v-model="record_data.diags"
                        label="Diags"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="objectives"
                        id="objectives"
                        placeholder="Objectives"
                        v-model="record_data.objectives"
                        label="Objectives"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="interventions"
                        id="interventions"
                        placeholder="Interventions"
                        v-model="record_data.interventions"
                        label="Interventions"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="goals"
                        id="goals"
                        placeholder="Goals"
                        v-model="record_data.goals"
                        label="Goals"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="summary"
                        id="summary"
                        placeholder="Summary"
                        v-model="record_data.summary"
                        label="Summary"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="recommends"
                        id="recommends"
                        placeholder="Recommends"
                        v-model="record_data.recommends"
                        label="Recommends"
                        :disabled="!isEditable"
                    /></div>
                </div>
                <!--form_extra-->
            </div>


            <ConfirmButton @click="updateRecord()"></ConfirmButton>
        </form>
    </div>
</template>
<script>
    const api_root = 'notes'

    export default {
        name: 'NoteDetail',
        props: {
            record_id: [String, Number],
        },
        data() {
            return {
                record_data : {
                    active : 0,
                    note_type : '',
                    note : '',
                    tags : '',
                    client_id : 1,
                    appt_id : 1,
                    signature_id : 1,
                    signed_dayt : '',
                    tx_review_id : 1,
                    prescription_id : 1,
                    signature_id2 : 1,
                    signed_dayt2 : '',
                    provider_id : 1,
                    attributes_values : '',
                    diags : '',
                    objectives : '',
                    interventions : '',
                    goals : '',
                    summary : '',
                    recommends : '',
                    user_id : 1,
                },
                isEditable : 1,
                record_actions : {
                    getRecordDetails: (id) => `/${api_root}/${id}`,
                    updateRecord: (id) => `/${api_root}/${id}`,
                }
            }
        },
        methods: {
            async init() {
                if (!(this.record_id === 'new')) {
                    const res = await this.$api.get(this.record_actions.getRecordDetails(this.record_id))

                    if (res.status === 404 || res.status === 500) {
                        this.$toasted.error('Could not find record')
                        return this.$router.replace({ name: 'NotFound' })
                    } else {
                        this.record_data = res.data.info[0]
                    }
                }
            },
            async updateRecord(e) {
                e.preventDefault()
                const isValid = await this.validateForm()

                if (isValid) {
                    try {
                        const res = await this.$api.put(this.record_actions.updateRecord(this.record_id), this.record_data)
                        const record_id = parseInt(res.data.recId[0])
                        this.$toasted.success('Successfully updated Record.')
                        await this.$router.push({ name: this.name, params: { record_id: record_id } })
                    } catch (err) {
                        this.$toasted.error('Failed to update Record. Please try again later.')
                    }
                }
            },
            async validateForm() {
                const isValid = 1 // await this.$refs.form.validate()

                if (!isValid) {
                    this.$toasted.error('Please fill out all required fields.')
                }

                return isValid
            },
        },
        async created() {
            await this.init()
        },
        watch: {
            async $route() {
                await this.init()
            },
        },
    }
</script>
